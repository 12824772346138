import BasePage from '../../../../../components/table_page';
import Form from './form';

export default {
  name: 'add_listen_node',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('tpm_budget_act_collect_field_jiulei');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.width = '1000px';
        this.modalConfig.title = val.name || '查看';
        this.openModal();
      }
    },
  },
};
